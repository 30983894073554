// Viewport units fix
let setViewportHieght = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

setViewportHieght();
// window.addEventListener('resize', setViewportHieght);

// Toggles menu open and closed
let menu = document.querySelector('.burger');
let body = document.querySelector('body');

menu.addEventListener('click', () => {
    body.classList.toggle('menu--open');
});

// Close menu if link is clicked
let menuLinks = document.querySelectorAll('.menu a');

menuLinks.forEach(link => {
    link.addEventListener('click', () => {
        body.classList.remove('menu--open');
    });
});

// Run the code if the location is the root page
if (window.location.pathname === '/') {
    let triggers = document.querySelectorAll('.product-li');
    let contents = [...document.querySelectorAll('.product-gal')];
    let types = contents.map(content => content.dataset.type);

    handleProductShow();

    triggers.forEach(trigger => {
        trigger.addEventListener('click', () => {
            triggers.forEach(trigger => trigger.classList.remove('active'));

            trigger.classList.add('active');
        });
    });

    window.addEventListener('hashchange', handleProductShow);

    function handleProductShow() {
        let { hash } = window.location;

        for (let i = 0;i < types.length;i++) {
            if (hash === `#${types[i]}`) {
                clearProducts();
                
                contents[i].classList.remove('hidden');
            }
        }
    }

    function clearProducts() {
        contents.forEach(el => {
            el.classList.add('hidden');
        });
    }
}